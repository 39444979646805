/* @import url("https://fonts.googleapis.com/css2?family=Shrikhand&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Margarine&family=Shrikhand&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@1,600;1,700&family=Fredoka:wght@300..700&family=Margarine&family=Shrikhand&display=swap"); */
/* esta se usa */
@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@1,600;1,700&family=Fredoka:wght@300..700&family=Indie+Flower&family=Margarine&family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Jaro:opsz@6..72&family=Patrick+Hand&display=swap");
/* fin se usan */
/* @import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&family=Patrick+Hand&display=swap"); */



@font-face {
  font-family: "waffle";
  src: local("waffle"), url(./resources/fonts/WaffleSoft.otf) format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#guidesNav{
  margin-top: 1%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.imgPreview{
  display: none;
  width: 100%;
}
.imgPreview2{
  display: block;
  width: 100%;
}
.imageLogoPreview{
  width: 100px;
  min-height: 100px;
  opacity: 30%;
}
.imageLogoPreviewEdition{
  width: 100px;
  min-height: 100px;
  opacity: 30%;
}
.iconLogo{
  width: 25%;
  margin-bottom: 5px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#navLogo{
  width: 18%;
}
#homeTitle {
  font-family: waffle;
  color: #44c6dd;
  font-size: 4.5rem;
  text-shadow: 3px 3px 3px #443f3f, 6px 6px 3px rgb(0, 0, 0);
}

.homeSubText {
  font-family: "Fredoka";
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  color: white;
  font-size: 1.5rem;
}

.homeSubText2 {
  font-family: "Fredoka";
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  color: white;
  font-size: 1.1rem;
}

.phistorySideBar {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

#homePageBanner {
  height: 91vh;
  /* height: auto; */
  width: 100vw;
  background-image: linear-gradient(24deg, rgba(200, 255, 255, .6) 4%, rgba(71, 15, 201, .8) 100%), url(./resources/images/people-working-elegant-cozy-office-space.jpg);
  background-size: cover;
  background-position: center;
  max-width: 100%;
  min-height: 100%;
}
#homepageBtnDiv{
  width: 100%;
  height: 100%;
  padding-left: -5%;
  /* padding-bottom: 15%; */
}

.navigBar {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.loginPage {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  height: 89.6vh;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  background-image: linear-gradient(24deg, rgba(200, 255, 255, .3) 4%, rgba(71, 15, 201, .2) 100%), url(./resources/images/6068325.png);
}
#loginGoogleBtn{
  margin-left: 22%;
}
#accountPage {
  height: 89.6vh;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  background-image: linear-gradient(24deg, rgba(200, 255, 255, .3) 4%, rgba(71, 15, 201, .2) 100%), url(./resources/images/6068325.png);
}
.passList{
  font-size: smaller;
  color: gray;
  text-align: justify;
}
#homeGuiaDeUso {
  font-family: "Patrick Hand", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.1rem;
  height: 89.6vh;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  background-image: linear-gradient(24deg, rgb(255, 255, 255, 0) 4%, rgba(255, 255, 255, 0) 100%), url(./resources/images/fondoModificado.jpg);
}
#homeGuiaDeUsoTitle{
  font-size: 3rem;
}

#homeContacto {
  font-family: "Patrick Hand", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.1rem;
  height: 70vh;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  background-image: linear-gradient(24deg, rgb(255, 255, 255, 0) 4%, rgba(255, 255, 255, 0) 100%), url(./resources/images/fondoModificado2.jpg);
}

#allGuides {
  font-family: "Patrick Hand", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.1rem;
  min-height: 100vh;
}

#allGuidesTitle {
  font-size: 3rem;
}

.allGuidesGifBtn {
  border: none;
}

#allGuidesModalBody {
  background-color: #c9c7c7;
}

#allGuidesCloseBtn {
  border: 2px solid purple;
}

.allGuidesDivGif {
  min-height: 96vh;
}

.menuProjects {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  min-height: 89.6vh;
  background-size: cover;
  background-position: center;
  height: auto;
  background-image: linear-gradient(90deg, rgba(53, 27, 116, 0.2) 20%, rgba(63, 37, 122, 0.2) 20%, rgba(12, 12, 12, 0.055) 70%), url(./resources/images/imagen2.png);
}
#allPsubtitle{
  font-size: smaller;
  padding: 0;
  margin: 0;
}
.meetingReport {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.reportPages {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  min-height: 89.6vh;
  background-size: cover;
  background-position: center;
  height: auto;
  background-image: linear-gradient(90deg, rgba(53, 27, 116, 0.2) 20%, rgba(63, 37, 122, 0.2) 20%, rgba(12, 12, 12, 0.055) 70%), url(./resources/images/imagen3.png);
}

.formStyles {
  /* background-image: linear-gradient(24deg,rgba(200, 255, 255, .6) 4%, rgba(71, 15, 201, .8) 100%); */
  /* background-color: #978BAF; */
  background-color: #7C89C2;
  width: 54rem;
}

.formPagesBackgroundPattern {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .6), rgba(255, 255, 255, .6)), url("./resources/images/lightGrayPattern.jpg");
  background-size: cover;
  background-repeat: repeat-y;
  min-height: 100vh;
  height: auto;
}

.NameProjectList {
  /* background-image: linear-gradient(96deg,rgba(200, 255, 255, .0) 4%, rgba(71, 15, 201, .0) 100%); */
  /* background-color: #978BAF; */
  background-color: #7C89C2;

}

#footer {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .1)), url("./resources/images/wave.png");
  color: white;
  height: 70px;
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
#rowFooter{
  height: 70px;
}

.myBtn {
  /* font-family: "Indie Flower", cursive;
  font-weight: 400;
  font-style: normal; */
  /* font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal; */
  font-family: "Jaro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
  color: white;
}

.myBtn:disabled {
  background-color: rgba(71, 15, 201, .5);
  border-color: rgba(71, 15, 201, .5);
  color: white;
}

.myBtn:hover {
  background-color: rgba(71, 15, 201, .6);
  border-color: rgba(71, 15, 201, .8);
  color: white;
}

.myCarouselBtn {
  background-color: rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
  --bs-btn-padding-y: .1rem;
  --bs-btn-padding-x: .1rem;
  --bs-btn-font-size: .01rem;

}

.swal-button {
  background-color: rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
  color: white;
}

.myActive {
  visibility: visible;

}

.notActive {
  visibility: hidden;
}

.selected {
  background-color: rgb(128, 195, 233);
  color: white;
}

.notSelected {
  background-color: white;
  color: black;
}

.bi::before {
  display: inline-block;
  content: "";
  vertical-align: -.125em;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

.languageIco {
  width: 40%;
}
#taskCard{
  width: 26rem;
}


/* <----------------------Media query changes mobile-------------------------> */

@media screen and (max-width:500px) {
  #navLogo{
    width: 100%;
    margin-top: 5px;
  }
  .navBtn{
    margin-top: 10px;
  }
  .languageIco {
    width: 20%;
    margin: 0;
    padding: 0;
  }
  .langBtn{
    width: fit-content;
    padding: 0;
    margin: 0;
  }
  #homeTitle {
    font-size: 2.5rem;
  }
  .homeSubText {
    font-size: 1rem;
  }

  .homeSubText2 {
    font-size: 0.6rem;
  }

  .myBtn {
    font-size: 0.6rem;
  }
  #homePageBanner{
    height: 90vh;
  }
  #homeGuiaDeUso {
    font-size: 0.8rem;

  }
  #homeGuiaDeUsoTitle{
    font-size: 1.8rem;
  }
  .homeGuiaSubTitle{
    font-size: 1.1rem;
  }
  .myCarouselBtn{
    font-size: 1.2rem;
  }
  #homeContacto{
    height: 95vh;
  }
  #footer{
    font-size: 0.8rem;
  }
  #pfooter{
    /* margin-top: 50px; */
    line-height: 0%;
    margin-bottom: 8px;
  }
  #loginGoogleBtn{
    margin-left: 0%;
  }
  #loginPage{
    height: 92vh;
  }
  #accountPage{
    height: 92vh;
  }
  .formStyles {
   width: 100%;
  }
  .reportPages{
    min-height: 150vh;
  }
  #taskCard{
    width: 92vw;
    margin: 0 0 10px 0;
    padding:0;
    background-color: transparent;
    border: none;
  }
  .mySelect{
    appearance: none;
  }
  #mobileTaskSelectTitle{
    background-color: transparent;
    border: none;
    width: 25%;
    
  }
}
/* <----------------------Media query changes mobile larger phones-------------------------> */
@media  screen and (max-width:500px) and (min-height:700px) {
  #homePageBanner{
    height: 70vh;
  }
  .reportPages{
    min-height: 96vh;
  }
}
/* <----------------------Media query changes mobile horizontal-------------------------> */
@media screen and (max-device-width:1000px) and (orientation:landscape){
  #navLogo{
    width: 50%;
    margin-top: 5px;
  }
  .navBtn{
    margin-top: 10px;
  }
  .languageIco {
    width: 10%;
    margin: 0;
    padding: 0;
  }
  .langBtn{
    width: fit-content;
    padding: 0;
    margin: 0;
  }
  #homePageBanner{
    height: 160vh;
  }
  #homeTitle {
    font-size: 2.5rem;
  }
  .homeSubText {
    font-size: 1rem;
  }
  .homeSubText2 {
    font-size: 0.6rem;
  }

  .myBtn {
    font-size: 0.6rem;
  }
  #homeGuiaDeUso {
    font-size: 0.8rem;
    height: 220vh;

  }
  #homeGuiaDeUsoTitle{
    font-size: 1.8rem;
  }
  .homeGuiaSubTitle{
    font-size: 1.1rem;
  }
  .myCarouselBtn{
    font-size: 1.2rem;
  }
  #homeContacto{
    height: 160vh;
  }
  #loginGoogleBtn{
    margin-left: 0%;
  }
  .reportPages{
    min-height: 120vh;
  }
}


/* <----------------------Media query changes laptop-------------------------> */
@media screen and (max-height:607px) and (min-width:1200px){
  #navLogo{
    width: 20%;
    margin-top: 5px;
  }
  .navBtn{
    margin-top: 10px;
  }
  .languageIco {
    width: 40%;
    margin-top: 50%;
    padding: 0;
  }
  .langBtn{
    width: fit-content;
    padding: 0;
    margin: 0;
  }
  #homePageBanner{
    height: 110vh;
  }
  #homeTitle {
    font-size: 3rem;
  }
  .homeSubText {
    font-size: 1.4rem;
  }
  .homeSubText2 {
    font-size: 0.9rem;
  }

  .myBtn {
    font-size: 0.9rem;
  }
  #homeGuiaDeUso {
    font-size: 1rem;
    height: 120vh;

  }
  #homeGuiaDeUsoTitle{
    font-size: 2rem;
  }
  .homeGuiaSubTitle{
    font-size: 1.5rem;
  }
  .myCarouselBtn{
    font-size: 0.5rem;
  }
  #homeContacto{
    height: 70vh;
  }
  #loginPage{
    height: 100vh;
  }
  #loginGoogleBtn{
    margin-left: 11%;
  }
  #accountPage{
    height: 100vh;
  }
  .iconLogo{
    width: 35%;
    margin-bottom: 5px;
  }
  
}

/* <----------------------Media query changes laptop1-------------------------> */
@media screen and (max-height:918px)and (min-width:1200px){
  #navLogo{
    width: 20%;
    margin-top: 5px;
  }
  .navBtn{
    margin-top: 10px;
  }
  .languageIco {
    width: 40%;
    margin-top: 50%;
    padding: 0;
  }
  .langBtn{
    width: fit-content;
    padding: 0;
    margin: 0;
  }
  #homePageBanner{
    height: 110vh;
  }
  #homeTitle {
    font-size: 3rem;
  }
  .homeSubText {
    font-size: 1.4rem;
  }
  .homeSubText2 {
    font-size: 0.9rem;
  }

  .myBtn {
    font-size: 0.9rem;
  }
  #homeGuiaDeUso {
    font-size: 1rem;
    height: 120vh;

  }
  #homeGuiaDeUsoTitle{
    font-size: 2rem;
  }
  .homeGuiaSubTitle{
    font-size: 1.5rem;
  }
  .myCarouselBtn{
    font-size: 0.5rem;
  }
  #homeContacto{
    height: 70vh;
  }
  #loginPage{
    height: 100vh;
  }
  #loginGoogleBtn{
    margin-left: 11%;
  }
  #accountPage{
    height: 100vh;
  }
  .iconLogo{
    width: 35%;
    margin-bottom: 5px;
  }
  
}